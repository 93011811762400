import Vue from "vue";

/*TITLE*/
document.title = "Ponte Burgo | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Ponte Burgo";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Ponte Burgo";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "linkfloor-feudal-natural-228x152x05--20230328110345.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-principal-urban-natural-nature-decoradolimit-urban-natural-nature--20230126120101.jpg";
Vue.prototype.$image_bath2 = "banyo-auxiliar-bottega-caliza-decorado-bottega-spiga--20230126120117.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "linkfloor-feudal-natural-228x152x05--20230328110345.jpg",
  },
  {
    src: "linkfloor-feudal-grey-228x152x05--20230328110350.jpg",
  },
  {
    src: "linkfloor-feudal-brown-228x152x05--20230328110354.jpg",
  },
  {
    src: "linkfloor-feudal-beige-228x152x05--20230328110359.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-principal-urban-natural-nature-decoradolimit-urban-natural-nature--20230126120101.jpg",
  },
  {
    src: "banyo-principal-urban-acero-nature-decorado-limit-urban-acero-nature--20230126120110.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-auxiliar-bottega-caliza-decorado-bottega-spiga--20230126120117.jpg",
  },
  {
    src: "banyo-auxiliar-bottega-acero-decorado-bottega-spiga--20230126120124.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/ponteburgo/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=pontevedra",
    text: "Pontevedra",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=pontevedra",
    text: "Pontevedra",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:986871877",
    text: "986871877",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@grupopirsa.com",
    text: "info@grupopirsa.com",
  },
];
